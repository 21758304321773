.coursepagebanner {
    position: relative;
    width: 100%;
    height: 500px; 
    background-image: url('../../assets/images/coursepagebanner-2.svg');
    
    background-size: cover;
    background-position: center;
    
  }

  /* .coursepagebanner h1
  {
    position: absolute;
    color: white;
    top: 100px;
  } */