.card {
    position: relative;
    width: 300px;
    max-width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image-container {
    position: relative;
    overflow: hidden;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .card:hover .card-image {
    transform: scale(1.1);
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    padding: 20px;
    text-align: center;
  }
  
  .card:hover .card-overlay {
    opacity: 1;
  }
  
  .card-overlay h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .card-overlay p {
    font-size: 1rem;
  }
  
  .arrow-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #007bff;
    border: none;
    border-radius: 50%;
    color: white;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .arrow-button:hover {
    background: #0056b3;
  }
  
  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }
  