.tab-link.active 
{
    
   background-color: #C9DFED;
   
}
.tab-link
{
    font-family:  var(--ff-poppins);
    border: 1px solid transparent;
    padding: 15px;
    border-radius: 30px;
    word-wrap: break-word;
    background-color: #E4E8EB;
    font-weight: bold;
}
.tab-link:hover
{
    background-color: #dfe1e3;
}