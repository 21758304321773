.blog-page {
    padding-top: 80px; /* Same height as the NavBar */
    text-align: center;
  }
  
  .blog-page h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  