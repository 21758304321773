.header .btn {
  display: none;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: var(--shadow-1);
  z-index: 8;
  overflow: hidden;

}

/* .header.active {
  position: fixed;
} */

.header .container,
.header-actions,
.navbar > .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.header-action-btn,
.nav-close-btn {
  position: relative;
  color: var(--eerie-black-1);
  font-size: 24px;
  transition: var(--transition-1);
}

.header-action-btn:is(:hover, :focus) {
  color: var(--kappel);
}

.header-action-btn .btn-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--kappel);
  color: var(--white);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-6);
  min-width: 20px;
  height: 20px;
  border-radius: var(--radius-circle);
}

.navbar {
  position: fixed;
  top: 0;
  left: -320px;
  background-color: var(--white);
  width: 100%;
  max-width: 320px;
  height: 100%;
  z-index: 2;
  transition: 0.25s var(--cubic-in);
}

.navbar.active {
  transform: translateX(320px);
  transition: 0.5s var(--cubic-out);
}

.navbar .wrapper {
  padding: 15px 20px;
  border-block-end: 1px solid var(--platinum);
}

.nav-close-btn {
  background-color: var(--white);
  box-shadow: var(--shadow-2);
  padding: 8px;
  border-radius: var(--radius-circle);
}

.nav-close-btn:is(:hover, :focus) {
  background-color: var(--kappel);
  color: var(--white);
}

.navbar-list {
  padding: 15px 20px;
}

.navbar-item:not(:last-child) {
  border-block-end: 1px solid var(--platinum);
}

.navbar-link {
  padding-block: 8px;
  font-weight: var(--fw-500);
  transition: var(--transition-1);
  color: black;
  display: block;
}

.navbar-link:is(:hover, :focus) {
  color: var(--kappel);
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: var(--black_80);
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: var(--transition-1);
}

.overlay.active {
  opacity: 1;
  pointer-events: all;
}

.btn-auth {
  font-weight: 700;
  border: 1px solid black;
  padding: 10px;
  border-radius: 30px;
  width: 100px;
}
.login:hover {
  background-color: lightgrey;
  border: none;
}
.signup {
  /* background-color: #1AB79D; */
  background-color: #052884;
  color: white;
  border: none;
}
.signup:hover {
  background: linear-gradient(-90deg, #052884 0%, #0056b3 100%);
}

.btn-auth {
  display: none;
}

/*MEDIA QUERY*/
@media (min-width: 575px) {
  /**
   * REUSED STYLE
   */

  .container {
    max-width: 520px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: 1fr;
  }

  /**
   * HEADER
   */

  .header .container {
    max-width: unset;
  }

  .header-actions {
    gap: 30px;
  }

  .btn-auth {
    display: none;
  }
}

/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 4.6rem;
    --fs-2: 3.8rem;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 720px;
  }

  .btn {
    padding: 15px 30px;
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: 1fr 1fr;
  }

  /**
   * HEADER
   */

  .header .container {
    padding-inline: 30px;
  }

  .header .btn {
    display: flex;
    padding: 10px 30px;
    margin-inline: 20px;
  }

  .btn-auth {
    display: none;
  }
}

/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 5.5rem;
    --fs-2: 4.5rem;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: repeat(4, 1fr);
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .btn-auth {
    display: block;
  }

  .nav-login {
    display: none;
  }

  /**
   * HERO
   */
}

@media (min-width: 1200px) {
  /**
   * CUSTOM PROPERTY
   */

  :root {
    /**
     * typography
     */

    --fs-1: 6.5rem;

    /**
     * spacing
     */

    --section-padding: 120px;
  }

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 1185px;
  }

  .shape {
    display: block;
  }

  .about-content,
  .video-card,
  .blog {
    position: relative;
  }

  /**
   * HEADER
   */

  .header-action-btn:last-child,
  .navbar .wrapper,
  .overlay {
    display: none;
  }

  .header.active {
    transform: translateY(-100%);
    animation: slideIn 0.5s ease forwards;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    display: flex;
    gap: 50px;
    padding: 0;
  }

  .navbar-item:not(:last-child) {
    border-block-end: none;
  }

  .navbar-link {
    color: var(--eerie-black-1);
    padding-block: 20px;
  }

  .header .btn {
    margin-inline-end: 0;
  }
  .btn-auth {
    display: block;
  }

  .nav-login {
    display: none;
  }
}
