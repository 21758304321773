* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tab-component {
  display: flex;
  max-width: 1200px;
  margin: 20px auto; /* Add margin for spacing */
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.tabs button {
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #052884;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: all 0.3s ease;
  width: 200px; /* Ensure consistent width */
}

.tabs button.active {
  background-color: #052884;
  color: white;
}

.content {
  flex-grow: 1;
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  animation: fadeIn 0.5s;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 300px;
  max-width: 100%;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h3 {
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.card p {
  font-size: 1rem;
  color: #666;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .tab-component {
    flex-direction: column;
  }

  .tabs {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
  }

  .tabs button {
    width: auto; /* Allow flexibility for mobile view */
    margin-right: 10px;
    margin-bottom: 0;
  }

  .content {
    padding: 10px;
  }
}
