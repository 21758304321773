.hero-section {
  padding-block-start: calc(var(--section-padding) + 80px) !important;
  background-image: url("../../assets/images/hero-bg.svg");
  overflow: hidden;
}

.hero-section .container {
  display: grid;
  gap: 40px;
}

.hero-text {
  color: var(--eerie-black-1);
  font-size: var(--fs-4);
  text-align: center;
  margin-block: 18px 20px;
}

.button-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  position: relative;
}

.hero-section .btn {
  margin-inline: auto;
  background-color: #052884;
  
}

.hero-section .btn-2 {
  margin-inline: auto;
  background-color: transparent;
  border: 2px solid #052884;
  color: #052884;

}

.hero-banner {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  align-items: flex-start;
  gap: 30px;
}

.hero-banner > .img-holder.one {
  border-top-right-radius: 70px !important;
  border-bottom-left-radius: 110px;
}

.hero-banner > .img-holder.two {
  border-top-left-radius: 50px;
  border-bottom-right-radius: 90px;
}


/* MEDIA QUERY */
@media (min-width: 575px) {

    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 520px;
      width: 100%;
      margin-inline: auto;
    }
  
    .grid-list { grid-template-columns: 1fr 1fr; }
  
    :is(.course, .blog) .grid-list { grid-template-columns: 1fr; }
  
  
  
   
  
  
    /**
     * HERO
     */
  
    .hero-banner { grid-template-columns: 1fr 0.9fr; }
  
  

  
  }
  
  
  
  
  
  /**
   * responsive for large than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 4.6rem;
      --fs-2: 3.8rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 720px; }
  
    .btn { padding: 15px 30px; }
  
    :is(.course, .blog) .grid-list { grid-template-columns: 1fr 1fr; }
  
  
  
   
  
    /**
     * HERO
     */
  
    .hero-section { padding-block-start: calc(var(--section-padding) + 90px); }
  
    .hero-section .container { gap: 50px; }
  
    .hero-text { margin-block-end: 30px; }
  
    .hero-banner {
      position: relative;
      z-index: 1;
    }
  
    .hero-banner .img-holder { max-width: max-content; }
  
    .hero-banner .img-holder.one { justify-self: flex-end; }
  
    .hero-banner .img-holder.two { margin-block-start: 100px; }
  
    .hero-shape-1 {
      display: block;
      position: absolute;
      bottom: -40px;
      left: -10px;
    }
  
  
  
}
  

  
@media (min-width: 992px) {
  /**
     * CUSTOM PROPERTY
     */

  html {
    /**
       * typography
       */

    --fs-1: 5.5rem;
    --fs-2: 4.5rem;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: repeat(4, 1fr);
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }

  /**
     * HERO
     */

  .hero-section .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .hero-section .section-title,
  .hero-text {
    text-align: left;
  }

  .hero-section .btn {
    margin-inline: 0;
  }
  .hero-section .btn-2 {
    margin-inline: 0;
  }

  
}

/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 6.5rem;

    /**
       * spacing
       */

    --section-padding: 120px;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 1185px;
  }

  .shape {
    display: block;
  }

  .about-content,
  .video-card,
  .blog {
    position: relative;
  }

  /**
     * HEADER
     */

  .header-action-btn:last-child,
  .navbar .wrapper,
  .overlay {
    display: none;
  }

  .header.active {
    transform: translateY(-100%);
    animation: slideIn 0.5s ease forwards;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .navbar,
  .navbar.active {
    all: unset;
  }

  .navbar-list {
    display: flex;
    gap: 50px;
    padding: 0;
  }

  .navbar-item:not(:last-child) {
    border-block-end: none;
  }

  .navbar-link {
    color: var(--eerie-black-1);
    padding-block: 20px;
  }

  .header .btn {
    margin-inline-end: 0;
  }

  /**
     * HERO
     */

  .hero-section {
    padding-block-start: calc(var(--section-padding) + 120px);
  }

  .hero-section .container {
    gap: 80px;
  }

  .hero-shape-2 {
    top: -80px;
    z-index: -1;
  }
  .hero-section .btn {
    margin-inline: 0;
  }
  .hero-section .btn-2 {
    margin-inline: 0;
  }
  /**
     * ABOUT
     */

  .about .container {
    gap: 110px;
  }

  .about-banner .img-holder {
    margin-inline: 0;
  }

  .about-shape-3 {
    top: -20px;
    left: -100px;
    z-index: -1;
  }

  .about-content {
    z-index: 1;
  }

  .about-shape-4 {
    top: 30px;
    right: -60px;
    z-index: -1;
  }

  /**
     * VIDEO
     */

  .video-shape-1 {
    top: -50px;
    left: 0;
  }

  .video-shape-2 {
    top: -80px;
    right: 120px;
    z-index: 1;
  }

  /**
     * BLOG
     */

  .blog-shape {
    top: 0;
    left: 0;
  }
}
