.course-card {
  position: relative;
  background-color: var(--white);
  border-radius: var(--radius-5);
  flex: 0 0 auto;
  width: 300px; 
  margin-right: 10px; 
  overflow: hidden;
}
.course-card .img-cover {
  transition: var(--transition-2);
}
.course-card:is(:hover, :focus-within) .img-cover {
  transform: scale(1.1);
}
.course-card :is(.abs-badge, .badge) {
  font-family: var(--ff-league_spartan);
  border-radius: var(--radius-3);
}

.course-card .abs-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--selective-yellow);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1;
  padding: 6px 8px;
  padding-block-end: 3px;
}
.course-card .abs-badge IonIcon {
  font-size: 18px;
  margin-block-end: 5px;
  --ionicon-stroke-width: 50px;
}
.course-card .card-content {
  padding: 25px;
}

.course-card .badge {
  background-color: var(--kappel_15);
  max-width: max-content;
  color: var(--kappel);
  line-height: 25px;
  padding-inline: 10px;
}

.course-card .card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-block: 15px 8px;
  transition: var(--transition-1);
}

.course-card .card-title:is(:hover, :focus) {
  color: var(--kappel);
}

.course-card :is(.wrapper, .rating-wrapper, .card-meta-list, .card-meta-item) {
  display: flex;
  align-items: center;
}

.course-card .wrapper {
  gap: 10px;
}

.course-card .rating-wrapper {
  gap: 3px;
}

.course-card .rating-wrapper ion-icon {
  color: var(--selective-yellow);
}

.course-card .rating-text {
  color: var(--eerie-black-1);
  font-size: var(--fs-6);
  font-weight: var(--fw-500);
}

.course-card .price {
  color: var(--radical-red);
  font-family: var(--ff-league_spartan);
  font-size: var(--fs-4);
  font-weight: var(--fw-600);
  margin-block: 8px 15px;
}

.course-card .card-meta-list {
  flex-wrap: wrap;
}

.course-card .card-meta-item {
  position: relative;
  gap: 5px;
}

.course-card .card-meta-item:not(:last-child)::after {
  content: "|";
  display: inline-block;
  color: var(--platinum);
  padding-inline: 10px;
}

.course-card .card-meta-item ion-icon {
  color: var(--quick-silver);
  --ionicon-stroke-width: 50px;
}

.course-card .card-meta-item .span {
  color: var(--eerie-black-1);
  font-size: var(--fs-7);
}

.course .btn {
  margin-inline: auto;
  margin-block-start: 60px;
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 575px screen
 */

@media (min-width: 575px) {
  /**
     * REUSED STYLE
     */

  .container {
    max-width: 520px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list {
    grid-template-columns: 1fr 1fr;
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: 1fr;
  }
}

/**
   * responsive for large than 768px screen
   */

@media (min-width: 768px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 4.6rem;
    --fs-2: 3.8rem;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 720px;
  }

  .btn {
    padding: 15px 30px;
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: 1fr 1fr;
  }
}

/**
   * responsive for large than 992px screen
   */

@media (min-width: 992px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 5.5rem;
    --fs-2: 4.5rem;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 960px;
  }

  .grid-list {
    grid-template-columns: repeat(4, 1fr);
  }

  :is(.course, .blog) .grid-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/**
   * responsive for large than 1200px screen
   */

@media (min-width: 1200px) {
  /**
     * CUSTOM PROPERTY
     */

  :root {
    /**
       * typography
       */

    --fs-1: 6.5rem;

    /**
       * spacing
       */

    --section-padding: 120px;
  }

  /**
     * REUSED STYLE
     */

  .container {
    max-width: 1185px;
  }

  .shape {
    display: block;
  }

  .about-content,
  .video-card,
  .blog {
    position: relative;
  }
}
