.about {
    padding-block-start: 0;
    overflow: hidden;
  }
  
  .about .container {
    display: grid;
    gap: 30px;
  }
  
  .about-banner {
    position: relative;
    z-index: 1;
  }
  
  .about-banner .img-holder { border-radius: var(--radius-10); }
  
  .about-shape-2 {
    display: block;
    bottom: -100px;
    left: -60px;
    animation: bounce 2.5s infinite;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% { transform: translateY(0); }
  
    40% { transform: translateY(-30px); }
  
    60% { transform: translateY(-15px); }
  }
  
  .about :is(.section-subtitle, .section-title, .section-text) {
    text-align: left;
  }
  
  .about-item {
    margin-block: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .about-item ion-icon {
    color: var(--selective-yellow);
    font-size: 20px;
    --ionicon-stroke-width: 50px;
  }
  
  .about-item .span {
    color: var(--eerie-black-1);
    font-family: var(--ff-league_spartan);
  }


/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 575px screen
 */

 @media (min-width: 575px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 520px;
    width: 100%;
    margin-inline: auto;
  }

  .grid-list { grid-template-columns: 1fr 1fr; }

  :is(.course, .blog) .grid-list { grid-template-columns: 1fr; }



  /**
   * HEADER
   */

  .header .container { max-width: unset; }

  .header-actions { gap: 30px; }



  /**
   * HERO
   */

  .hero-banner { grid-template-columns: 1fr 0.9fr; }



  /**
   * VIDEO
   */

  .video .play-btn { padding: 25px; }



  /**
   * STATS
   */

  .stats-card { padding: 40px 30px; }



  /**
   * FOOTER
   */

  .footer-brand,
  .footer-list:last-child { grid-column: 1 / 3; }

  .newsletter-form {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .newsletter-form .input-field { margin-block-end: 0; }

  .newsletter-form .btn { min-width: max-content; }

}





/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 4.6rem;
    --fs-2: 3.8rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }

  .btn { padding: 15px 30px; }

  :is(.course, .blog) .grid-list { grid-template-columns: 1fr 1fr; }



  /**
   * HEADER
   */

  .header .container { padding-inline: 30px; }

  .header .btn {
    display: flex;
    padding: 10px 30px;
    margin-inline: 20px;
  }



  /**
   * HERO
   */

  .hero { padding-block-start: calc(var(--section-padding) + 90px); }

  .hero .container { gap: 50px; }

  .hero-text { margin-block-end: 30px; }

  .hero-banner {
    position: relative;
    z-index: 1;
  }

  .hero-banner .img-holder { max-width: max-content; }

  .hero-banner .img-holder.one { justify-self: flex-end; }

  .hero-banner .img-holder.two { margin-block-start: 100px; }

  .hero-shape-1 {
    display: block;
    position: absolute;
    bottom: -40px;
    left: -10px;
  }



  /**
   * ABOUT
   */

  .about { padding-block-start: 50px; }

  .about-banner {
    padding: 60px;
    padding-inline-end: 0;
  }

  .about-banner .img-holder {
    max-width: max-content;
    margin-inline: auto;
  }

  .about-shape-1 {
    display: block;
    top: -40px;
    right: -70px;
  }



  /**
   * FOOTER
   */

  .footer-brand,
  .footer-list:last-child { grid-column: auto; }

  .newsletter-form .btn { padding-block: 10px; }

}





/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 5.5rem;
    --fs-2: 4.5rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }

  .grid-list { grid-template-columns: repeat(4, 1fr); }

  :is(.course, .blog) .grid-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * HERO
   */

  .hero .container {
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .hero .section-title,
  .hero-text { text-align: left; }

  .hero .btn { margin-inline: 0; }



  /**
   * ABOUT
   */

  .about .container {
    grid-template-columns: 1fr 0.6fr;
    align-items: center;
    gap: 60px;
  }



  /**
   * VIDEO
   */

  .video-banner {
    max-width: 75%;
    margin-inline: auto;
  }



  /**
   * FOOTER
   */

  .footer .grid-list { grid-template-columns: 1fr 0.6fr 0.6fr 1.2fr; }

}





/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 6.5rem;

    /**
     * spacing
     */

    --section-padding: 120px;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 1185px; }

  .shape { display: block; }

  .about-content,
  .video-card,
  .blog { position: relative; }



  /**
   * HEADER
   */

  .header-action-btn:last-child,
  .navbar .wrapper,
  .overlay { display: none; }

  .header.active {
    transform: translateY(-100%);
    animation: slideIn 0.5s ease forwards;
  }

  @keyframes slideIn {
    0% { transform: translateY(-100%); }
    100% { transform: translateY(0); }
  }

  .navbar,
  .navbar.active { all: unset; }

  .navbar-list {
    display: flex;
    gap: 50px;
    padding: 0;
  }

  .navbar-item:not(:last-child) { border-block-end: none; }

  .navbar-link {
    color: var(--eerie-black-1);
    padding-block: 20px;
  }

  .header .btn { margin-inline-end: 0; }



  /**
   * HERO
   */

  .hero { padding-block-start: calc(var(--section-padding) + 120px); }

  .hero .container { gap: 80px; }

  .hero-shape-2 {
    top: -80px;
    z-index: -1;
  }



  /**
   * ABOUT
   */

  .about .container { gap: 110px; }

  .about-banner .img-holder { margin-inline: 0; }

  .about-shape-3 {
    top: -20px;
    left: -100px;
    z-index: -1;
  }

  .about-content { z-index: 1; }

  .about-shape-4 {
    top: 30px;
    right: -60px;
    z-index: -1;
  }



  /**
   * VIDEO
   */

  .video-shape-1 {
    top: -50px;
    left: 0;
  }

  .video-shape-2 {
    top: -80px;
    right: 120px;
    z-index: 1;
  }



  /**
   * BLOG
   */

  .blog-shape {
    top: 0;
    left: 0;
  }

}